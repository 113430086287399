import React from 'react';
import './getInTouchRecruiting.scss';

const iframeStyle = {
  width: '100%',
  height: '650px',
  border: 'none', // Equivalent to frameBorder="0"
  overflow: 'auto', // Equivalent to scrolling="yes"
};

function GetInTouchRecruiting() {
  return (
    <div className="row getInTouchRecruiting yellow-bg px-5" id="getInTouch">
      <div className="col-md-6 col-12 getInTouchRecruiting">
        <h3 className="color-4 subheadingCategory"> Unser massgeschneidertes Recruiting </h3>
        <h2 className="getInTouchRecruitingPara1">
          Perfekt abgestimmt auf{' '}
          <span className="getInTouchRecruitingPara1InnerText">
            Steuerberater, Notare und Anwälte
          </span>
        </h2>
        <p className="getInTouchRecruitingPara2">
          Beginnen Sie Ihre Reise mit unserem{' '}
          <span className="bold">2-Minuten-Recruitment-System </span> jetzt, indem Sie einen Termin
          für eine <span className="bold"> kostenlose Erstberatung per Video-Call </span>{' '}
          vereinbaren.
        </p>
        <p className="getInTouchRecruitingPara2">
          Wählen Sie einfach den für Sie passenden Termin aus und wir freuen uns darauf, Ihnen zu
          zeigen, wie wir Ihr Recruiting revolutionieren können.
        </p>
      </div>
      <div className="col-md-6 col-12 getInTouchRecruiting">
        <iframe
          src="https://meetus.build-ideas.de/ericdelaporte/expertengespraech-eric?is_widget=1&view=compact"
          style={iframeStyle}
          title="meetme"
        />
      </div>
    </div>
  );
}

export default GetInTouchRecruiting;
