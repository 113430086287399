import React from 'react';
import './philosophy.scss';
import secondImage from 'assets/company/second.png';
import { useTranslation } from 'react-i18next';

type List = {
  header: string;
  para: string;
};

type PhilosophyProps = {
  navigateToContactUs: () => void;
};
function Philosophy({ navigateToContactUs }: PhilosophyProps) {
  const { t } = useTranslation();
  const list: List[] = t('company.section2.body', { returnObjects: true }) as [];
  return (
    <div className="row">
      <div className="col-md-6 col-12 d-md-none d-xl-block">
        <img src={secondImage} alt="firstImage img-responsive" className="philosophyImg" />
      </div>
      <div className="col-md-6 col-12 d-md-none d-xl-block">
        <div className="philosophy">
          <h1 className="philosophyPara1">
            {t('company.section2.header.part1')}&nbsp;
            <span className="philosophyPara1InnerText">
              {t('company.section2.header.part2')}
              <br />
              {t('company.section2.header.part3')}
            </span>
          </h1>
          <p className="philosophyPara2">{t('company.section2.para')}</p>
          <ul className="philosophyList">
            {list.map((item) => {
              return (
                <li>
                  {item.header}
                  <br />
                  <span>{item.para}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="col-12 d-none d-md-block d-xl-none">
        <img
          src={secondImage}
          alt="firstImage img-responsive"
          className="philosophyImg float-start w-75"
        />
        <div className="philosophy">
          <h1 className="philosophyPara1">
            {t('company.section2.header.part1')}
            <span className="philosophyPara1InnerText">
              {t('company.section2.header.part2')}
              <br />
              {t('company.section2.header.part3')}
            </span>
          </h1>
          <p className="philosophyPara2">{t('company.section2.para')}</p>
          <ul className="philosophyList">
            {list.map((item) => {
              return (
                <li>
                  {item.header}
                  <br />
                  <span>{item.para}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center pb-5">
        <div className="philosophy">
          <button type="button" className="btn philosophyButton" onClick={navigateToContactUs}>
            {t('company.section2.cta_button')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Philosophy;
