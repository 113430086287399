import React from 'react';
import './recruitmentLevel.scss';
import icon_clock from 'assets/recruitment/icon_clock.png';
import icon_target from 'assets/recruitment/icon_target.png';
import icon_diagram from 'assets/recruitment/icon_diagram.png';
import { useTranslation } from 'react-i18next';
import ContactForm from '../ContactForm/contactForm';

function RecruitmentLevel() {
  const { t } = useTranslation();
  return (
    <div className="row container6 recruitmentLevel">
      <div className="col-md-12 col-12 center">
        <h3 className="subheadingCategory">{t('recruiting.section2.heading.part1')}</h3>
        <h2 className="recruitmentLevelPara2">
          {' '}
          {t('recruiting.section2.heading.part2')} <br />
          <span className="colorGold"> {t('recruiting.section2.heading.part3')} </span>{' '}
        </h2>
      </div>
      <div className="col-md-12 col-12 flex">
        <ul className="list">
          <li className="list-item">
            <div className="recruitmentLevel-icon">
              <img src={icon_clock} alt="icon_clock img-responsive" />
            </div>
            <div className="flex col-start">
              <h4 className="recruitmentLevelSubheading4">Zeitersparnis</h4>
              <p className="recruitmentLevelPara3">
                Das 2-Minuten-Recruitment-System beschleunigt den Einstellungsprozess erheblich.
              </p>
            </div>
          </li>
          <li className="list-item">
            <div className="recruitmentLevel-icon">
              <img src={icon_diagram} alt="icon_diagram img-responsive" />
            </div>
            <div className="flex col-start">
              <h4 className="recruitmentLevelSubheading4">Effizienz</h4>
              <p className="recruitmentLevelPara3">
                Der gesamte Prozess ist automatisiert und digitalisiert, ein Muss für
                Effizienzsteigerung.
              </p>
            </div>
          </li>
          <li className="list-item">
            <div className="recruitmentLevel-icon">
              <img src={icon_target} alt="icon_target img-responsive" />
            </div>
            <div className="flex col-start">
              <h4 className="recruitmentLevelSubheading4">Genauigkeit</h4>
              <p className="recruitmentLevelPara3">
                Mit der Checkliste können Sie sicherstellen, dass nur die relevantesten Kandidaten
                ausgewählt werden.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="col-md-12 col-12 flex recruitmentLevelPara3">
        <ContactForm />
      </div>
    </div>
  );
}

export default RecruitmentLevel;
