import React from 'react';
import './fullWidthImage.scss';
import podcast from 'assets/podcast/mikrofon-fuer-das-interview.jpg';

function FullWidthImage() {
  return (
    <div className="row full-width">
      <div className="image-container">
        <img src={podcast} alt="digitalImage img-fluid" className="centered-image" />
      </div>
    </div>
  );
}

export default FullWidthImage;
