import React from 'react';
import './recruiting.scss';
import BuildDigital from 'components/recruitingComponents/BuildDigital/buildDigital';
import RecruitmentLevel from 'components/recruitingComponents/RecruitmentLevel/recruitmentLevel';
import FullWidthImage from 'components/recruitingComponents/FullWidthImage/fullWidthImage';
import Branding from 'components/recruitingComponents/Branding/branding';
import SystemSuperiority from 'components/recruitingComponents/SystemSuperiority/systemSuperiority';
import GetInTouchRecruiting from 'components/recruitingComponents/getInTouchRecruiting/getInTouchRecruiting';

function Recruiting() {
  return (
    <>
      <div className="container-fluid">
        <BuildDigital />
      </div>

      <div className="container-fullWidth">
        <RecruitmentLevel />
      </div>

      <div className="container-fullWidth">
        <FullWidthImage />
      </div>

      <div className="container-fluid">
        <Branding />
      </div>

      <div className="container-fullWidth">
        <SystemSuperiority />
      </div>

      <div className="container-fullWidth">
        <GetInTouchRecruiting />
      </div>
    </>
  );
}

export default Recruiting;
