import React, { useState } from 'react';

function ContactForm() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // your Zapier webhook URL
    const webhookURL = 'https://hooks.zapier.com/hooks/catch/15344560/31en6ef/';

    try {
      fetch(webhookURL, {
        method: 'POST',
        body: JSON.stringify({
          email,
          // your data here
        }),
        // TODO: visuelle Bestätigung für Erfolg auf Seite einbauen
      }).then((response) => response.json());
    } catch (error) {
      // d
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex column col-center contact-form">
      <p className="center">
        Interesse an den <span className="specific">weiteren 30 Gründen?</span>
        <br />
        Einfach hier anfordern:
      </p>
      <label htmlFor="email">
        E-Mail*:
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </label>
      <button type="submit" className="btn contactFormButton">
        {' '}
        Abschicken{' '}
      </button>
    </form>
  );
}

export default ContactForm;
