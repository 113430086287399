import React from 'react';
import './genericInfo.scss';
import { useTranslation } from 'react-i18next';

function GenericInfo() {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col py-5">
        <h2 className="genericInfoPara1">{t('company.section3.heading')}</h2>
        <h3 className="genericInfoPara2 mt-3">{t('company.section3.para')}</h3>
      </div>
    </div>
  );
}

export default GenericInfo;
