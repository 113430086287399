import React from 'react';
import './impressum.scss';
import { useTranslation } from 'react-i18next';

function Impressum() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid impressumContainer">
      <div className="row">
        <div className="col">
          <div className="impressumContaineInner">
            {/* div 1 starts */}
            <div>
              <h2 className="impressumHeading1">{t('imprint.section1.heading')}</h2>
              <p className="impressumContent">
                {t('imprint.section1.body.part1')}
                <br />
                {t('imprint.section1.body.part2')}
                <br />
                {t('imprint.section1.body.part3')}
              </p>
              <p className="impressumContent">
                {t('imprint.section1.body.part4')}
                <br />
                {t('imprint.section1.body.part5')}
              </p>
              <p className="impressumContent">
                <span className="impressumContentInner">{t('imprint.section1.body.part6')}</span>
                <br />
                <span>{t('imprint.section1.body.part7')}</span>
              </p>
            </div>
            {/* div 1 ends */}
            {/* div 2 starts */}
            <div>
              <h2 className="impressumHeading1">{t('imprint.section2.heading')}</h2>
              <p className="impressumContent">
                {t('imprint.section2.body.part1')}
                <br />
                {t('imprint.section2.body.part2')}
              </p>
            </div>
            {/* div 2 ends */}
            {/* div 3 starts */}
            <div>
              <h2 className="impressumHeading1">{t('imprint.section3.heading')}</h2>
              <p className="impressumContent">
                {t('imprint.section3.body.part1')}
                <br />
                {t('imprint.section3.body.part2')}
              </p>
            </div>
            {/* div 3 ends */}
            {/* div 4 starts */}
            <div>
              <h2 className="impressumHeading1">{t('imprint.section4.heading')}</h2>
              <p className="impressumContent">
                {t('imprint.section4.body.part1')}
                <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.
                <br />
                {t('imprint.section4.body.part2')}
              </p>
              <p className="impressumContent">{t('imprint.section4.body.part3')}</p>
              {/* sub heading 1 starts */}
              <h3 className="impressumHeading2">{t('imprint.section4.subSection1.heading')}</h3>
              <p className="impressumContent">{t('imprint.section4.subSection1.body.part1')}</p>
              <p className="impressumContent">{t('imprint.section4.subSection1.body.part2')}</p>
              {/* sub heading 1 ends */}
              {/* sub heading 2 starts */}
              <h3 className="impressumHeading2">{t('imprint.section4.subSection2.heading')}</h3>
              <p className="impressumContent">{t('imprint.section4.subSection2.body.part1')}</p>
              <p className="impressumContent">{t('imprint.section4.subSection2.body.part2')}</p>
              {/* sub heading 2 ends */}
              {/* sub heading 3 starts */}
              <h3 className="impressumHeading2">{t('imprint.section4.subSection3.heading')}</h3>
              <p className="impressumContent">{t('imprint.section4.subSection3.body.part1')}</p>
              <p className="impressumContent">{t('imprint.section4.subSection3.body.part2')}</p>
              <p className="impressumContent">
                {t('imprint.section4.subSection3.body.part3')}{' '}
                <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
              </p>
              {/* sub heading 3 ends */}
            </div>
            {/* div 4 ends */}
            <div>
              <h2 className="impressumHeading1">{t('imprint.section5.heading')}</h2>
              <h3 className="impressumHeading2">{t('imprint.section5.subHeading')}</h3>
              <p className="impressumContent">
                {t('imprint.section5.body.part1')}{' '}
                <a href="https://de.freepik.com/fotos-kostenlos/mikrofon-fuer-das-interview_7821980.htm">
                  Freepik
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Impressum;
