import React from 'react';
// importing Routing Requirements
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// importing bootstrap
import 'bootstrap/dist/css/bootstrap.css';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/footer';
import 'App.scss';
import Home from 'pages/home/Home';
import Unternehmen from 'pages/unternehmen/unternehmen';
import Impressum from 'pages/Impressum/impressum';
import Datenschutz from 'pages/Datenschutz/datenschutz';
import Recruiting from 'pages/Recruiting/recruiting';
import Podcast from 'pages/Podcast/podcast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

function App() {
  return (
    <>
      <button type="button" onClick={topFunction} id="toTop">
        <FontAwesomeIcon className="locateIcon" icon={faArrowUp} />
      </button>
      <Router>
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="Unternehmen" element={<Unternehmen />} />
          <Route path="impressum" element={<Impressum />} />
          <Route path="datenschutz" element={<Datenschutz />} />
          <Route path="recruiting" element={<Recruiting />} />
          <Route path="podcast" element={<Podcast />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
