import React, { useRef } from 'react';
import './unternehmen.scss';
import BuildDigital from 'components/unternehmenComponents/BuildDigital/buildDigital';
import GetInTouchUnternehmen from 'components/homeComponents/GetInTouch/getInTouch';
import GenericInfo from 'components/unternehmenComponents/GenericInfo/genericInfo';
import Philosophy from 'components/unternehmenComponents/Philosophy/philosophy';

function Unternehmen() {
  const contactRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      {/* Container 1 Starts */}
      <div className="container-fluid">
        <BuildDigital />
      </div>
      {/* Container 1 Ends */}

      {/* Container 2 Starts */}
      <div className="container-fluid">
        <Philosophy navigateToContactUs={handleButtonClick} />
      </div>
      {/* Container 2 Ends */}

      {/* Container 3 Starts */}
      <div className="container-fluid container3GenericInfo">
        <GenericInfo />
      </div>
      {/* Container 3 Ends */}

      {/* Container 4 Ends */}
      <div className="container-fluid container4GetInTouch">
        <GetInTouchUnternehmen />
      </div>
      {/* Container 4 Ends */}
    </>
  );
}

export default Unternehmen;
