import React from 'react';
import './systemSuperiority.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import logo from 'assets/build_ideas_alternative_white.svg';

function SystemSuperiority() {
  return (
    <div className="row container6 systemSuperiority">
      <div className="col-md-12 col-12 systemSuperioritySection1">
        <h3 className="subheadingCategory">Warum unser System den Anderen überlegen ist</h3>
      </div>
      <div className="col-md-12 col-12 flex gap-3 my-5 systemSuperioritySection2">
        <div className="card">
          <div className="card-header">Empfehlungen & Netzwerk</div>
          <div className="card-body">
            <ul>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Unvorher&shy;sehbar
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Ressourcen&shy;intensiv
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Ad-hoc-Lösung
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Limitierte Verbreitung
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header">Headhunter & Vermittler </div>
          <div className="card-body">
            <ul>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Unverhältnis&shy;mäßig teuer
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Unqualifizierte Kandidaten
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Hohe Gehalts&shy;vorstellungen
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Mitarbeiter wechseln oft
              </li>
              <li>
                {' '}
                <FontAwesomeIcon icon={faXmark} size="xs" /> Oft Kosten ohne Kandidaten
              </li>
            </ul>
          </div>
        </div>
        <div className="card">
          <div className="card-header">Social Media Agenturen</div>
          <div className="card-body">
            <ul>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Liefern Kontakte statt echten
                Bewerbungen
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Oft keine individuelle
                Kandidaten&shy;ansprache
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Intransparente Arbeitsweise
              </li>
              <li>
                <FontAwesomeIcon icon={faXmark} size="xs" /> Hohes Maß an Zuarbeit
              </li>
            </ul>
          </div>
        </div>
        <div className="card specific">
          <div className="card-header">
            <img src={logo} alt="Logo" height={40} />
          </div>
          <div className="card-body">
            <ul>
              <li>
                {' '}
                <FontAwesomeIcon icon={faCheck} size="xs" /> Qualifizierte Bewerbungen
              </li>
              <li>
                {' '}
                <FontAwesomeIcon icon={faCheck} size="xs" /> Bewährtes, planbares, langfristiges
                System
              </li>
              <li>
                {' '}
                <FontAwesomeIcon icon={faCheck} size="xs" /> Mehr freie Zeit für Sie
              </li>
              <li>
                {' '}
                <FontAwesomeIcon icon={faCheck} size="xs" /> Völlige Transparenz
              </li>
              <li>
                {' '}
                <FontAwesomeIcon icon={faCheck} size="xs" /> Kandidaten, die von Ihnen als
                Arbeitgeber überzeugt sind
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemSuperiority;
