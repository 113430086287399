import React from 'react';
import './datenschutz.scss';
import { useTranslation } from 'react-i18next';

function Datenschutz() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid datenschutzContainer">
      <div className="row">
        <div className="col">
          <div className="datenschutzContaineInner">
            {/* div 1 starts */}
            <div>
              <h2 className="datenschutzHeading1">{t('privacy.section1.heading')}</h2>
              <h3 className="datenschutzHeading2">{t('privacy.section1.subHeading.part1')}</h3>
              <p className="datenschutzContent">{t('privacy.section1.body.part1')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section1.subHeading.part2')}</h3>
              <p className="datenschutzHeading3GreyBold">{t('privacy.section1.body.part2')}</p>
              <p className="datenschutzContent">{t('privacy.section1.body.part3')}</p>
              <p className="datenschutzHeading3GreyBold">{t('privacy.section1.body.part4')}</p>
              <p className="datenschutzContent">{t('privacy.section1.body.part5')}</p>
              <p className="datenschutzContent">{t('privacy.section1.body.part6')}</p>
              <p className="datenschutzHeading3GreyBold">{t('privacy.section1.body.part7')}</p>
              <p className="datenschutzContent">{t('privacy.section1.body.part8')}</p>
              <p className="datenschutzHeading3GreyBold">{t('privacy.section1.body.part9')}</p>
              <p className="datenschutzContent">{t('privacy.section1.body.part10')}</p>
              <p className="datenschutzContent">{t('privacy.section1.body.part11')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section1.subHeading.part3')}</h3>
              <p className="datenschutzContent">{t('privacy.section1.body.part12')}</p>
              <p className="datenschutzContent">{t('privacy.section1.body.part13')}</p>
            </div>
            {/* div 1 ends */}
            {/* div 2 starts */}
            <div>
              <h2 className="datenschutzHeading1">{t('privacy.section2.heading')}</h2>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part1')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part1')}</p>
              <p className="datenschutzContent">{t('privacy.section2.body.part2')}</p>
              <p className="datenschutzContent">{t('privacy.section2.body.part3')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part2')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part4')}</p>
              <p className="datenschutzContent">
                {t('privacy.section2.body.part5')}
                <br />
                {t('privacy.section2.body.part6')}
                <br />
                {t('privacy.section2.body.part7')}
              </p>
              <p className="datenschutzContent">
                {t('privacy.section2.body.part8')}
                <br />
                E-Mail: kontakt@build-ideas.de
              </p>
              <p className="datenschutzContent">{t('privacy.section2.body.part9')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part3')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part10')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part4')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part11')}</p>
              <p className="datenschutzContent">{t('privacy.section2.body.part12')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part5')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part13')}</p>
              <h3 className="datenschutzHeading2">REC{t('privacy.section2.subheading.part6')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part14')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part7')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part15')}</p>
              <p className="datenschutzContent">{t('privacy.section2.body.part16')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part8')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part17')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part9')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part18')}</p>
              <ul className="datenschutzContent">
                <li>{t('privacy.section2.list.part1')}</li>
                <li>{t('privacy.section2.list.part2')}</li>
                <li>{t('privacy.section2.list.part3')}</li>
                <li>{t('privacy.section2.list.part4')}</li>
              </ul>
              <p className="datenschutzContent">{t('privacy.section2.body.part19')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section2.subheading.part10')}</h3>
              <p className="datenschutzContent">{t('privacy.section2.body.part20')}</p>
            </div>
            {/* div 2 ends */}

            {/* div 3 starts */}
            <div>
              <h2 className="datenschutzHeading1">{t('privacy.section3.heading')}</h2>
              <h3 className="datenschutzHeading2">{t('privacy.section3.subHeading.part1')}</h3>
              <p className="datenschutzContent">{t('privacy.section3.body.part1')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part2')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part3')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part4')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part5')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section3.subHeading.part2')}</h3>
              <p className="datenschutzContent">{t('privacy.section3.body.part6')}</p>
              <ul className="datenschutzContent">
                <li>{t('privacy.section3.list.part1')}</li>
                <li>{t('privacy.section3.list.part2')}</li>
                <li>{t('privacy.section3.list.part3')}</li>
                <li>{t('privacy.section3.list.part4')}</li>
                <li>{t('privacy.section3.list.part5')}</li>
                <li>{t('privacy.section3.list.part6')}</li>
              </ul>
              <p className="datenschutzContent">{t('privacy.section3.body.part7')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part8')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section3.subHeading.part3')}</h3>
              <p className="datenschutzContent">{t('privacy.section3.body.part9')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part10')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part11')}</p>
              <h3 className="datenschutzHeading2">{t('privacy.section3.subHeading.part4')}</h3>
              <p className="datenschutzContent">{t('privacy.section3.body.part12')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part13')}</p>
              <p className="datenschutzContent">{t('privacy.section3.body.part14')}</p>
            </div>
            {/* div 3 ends */}

            {/* div 4 starts */}
            <div>
              <h2 className="datenschutzHeading1">{t('privacy.section4.heading')}</h2>
              <h3 className="datenschutzHeading2">{t('privacy.section4.subHeading')}</h3>
              <div className="datenschutzDarkBg">
                <p className="datenschutzHeading3GreyBold">{t('privacy.section4.body.part1')}</p>
                <p className="datenschutzContent">{t('privacy.section4.body.part2')}</p>
                <p className="datenschutzContent">{t('privacy.section4.body.part3')}</p>
                <p className="datenschutzContent">{t('privacy.section4.body.part4')}</p>
                <ul className="datenschutzContent">
                  <li>{t('privacy.section4.list.part1')}</li>
                  <li>{t('privacy.section4.list.part2')}</li>
                  <li>{t('privacy.section4.list.part3')}</li>
                  <li>{t('privacy.section4.list.part4')}</li>
                  <li>{t('privacy.section4.list.part5')}</li>
                  <li>{t('privacy.section4.list.part6')}</li>
                </ul>
                <p className="datenschutzContent">{t('privacy.section4.body.part5')}</p>
                <p className="datenschutzContent">{t('privacy.section4.body.part6')}</p>
                <p className="datenschutzContent">{t('privacy.section4.body.part7')}</p>
                <p className="datenschutzContent">
                  {t('privacy.section4.body.part8')}
                  <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
                    {t('privacy.section4.body.a')}
                  </a>{' '}
                  {t('privacy.section4.body.part9')}
                </p>
              </div>
            </div>
            {/* div 4 ends */}

            {/* div 5 starts */}
            <div>
              <h2 className="datenschutzHeading1">{t('privacy.section5.heading')}</h2>
              <h3 className="datenschutzHeading2">{t('privacy.section5.subHeading')}</h3>
              <p className="datenschutzContent">{t('privacy.section5.body.part1')}</p>
              <p className="datenschutzContent">{t('privacy.section5.body.part2')}</p>
              <p className="datenschutzContent">{t('privacy.section5.body.part3')}</p>
              <p className="datenschutzContent">
                {t('privacy.section5.body.part4')}
                <span className="datenschutzHeading3GreyBold">
                  {t('privacy.section5.body.span')}
                </span>
              </p>
            </div>
            {/* div 5 ends */}

            {/* div 6 starts */}
            <div>
              <h2 className="datenschutzHeading1">{t('privacy.section6.heading')}</h2>
              <h3 className="datenschutzHeading2">{t('privacy.section6.subHeading.part1')}</h3>
              <p className="datenschutzContent">{t('privacy.section6.body.part1')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part2')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part3')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part4')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part5')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part6')}</p>
              <p className="datenschutzContent">
                {t('privacy.section6.body.part7')}
                <a href="https://policies.google.com/privacy?hl=de">
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3 className="datenschutzHeading2">{t('privacy.section6.subHeading.part2')}</h3>
              <p className="datenschutzContent">{t('privacy.section6.body.part8')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part9')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part10')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part11')}</p>
              <p className="datenschutzContent">
                {t('privacy.section6.body.part12')}
                <a href="https://vimeo.com/privacy">https://vimeo.com/privacy</a>.
              </p>
              <h3 className="datenschutzHeading2">{t('privacy.section6.subHeading.part3')}</h3>
              <p className="datenschutzContent">{t('privacy.section6.body.part13')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part14')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part15')}</p>
              <p className="datenschutzContent">
                {t('privacy.section6.body.part16')}
                <a href="https://developers.google.com/fonts/faq">
                  https://developers.google.com/fonts/faq
                </a>{' '}
                {t('privacy.section6.body.part17')}
                <a href="https://policies.google.com/privacy?hl=de">
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3 className="datenschutzHeading2">{t('privacy.section6.subHeading.part4')}</h3>
              <p className="datenschutzContent">{t('privacy.section6.body.part18')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part19')}</p>
              <p className="datenschutzContent">{t('privacy.section6.body.part20')}</p>
              <p className="datenschutzContent">
                {t('privacy.section6.body.part21')}
                <a href="https://policies.google.com/privacy?hl=de">
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <p className="datenschutzContent">
                {t('privacy.section6.body.part22')}
                <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
              </p>
            </div>
            {/* div 6 ends */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datenschutz;
