import React from 'react';
import './buildDigital.scss';
import firstImage from 'assets/company/first.png';
import { useTranslation } from 'react-i18next';

function BuildDigital() {
  const { t } = useTranslation();
  return (
    <div className="row flex-column-reverse flex-md-row">
      <div className="col-md-6 col-12">
        <div className="buildDigital">
          <h1 className="buildDigitalPara1">
            {t('company.section1.heading.part1')}
            <br />
            <span className="buildDigitalPara1InnerText">
              {t('company.section1.heading.part2')}
            </span>
          </h1>
          <p className="buildDigitalPara2">{t('company.section1.para')}</p>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <img src={firstImage} alt="firstImage img-responsive" className="buildDigitalImg" />
      </div>
    </div>
  );
}

export default BuildDigital;
