import React from 'react';
import './genericInfo.scss';
import biBulb from 'assets/podcast/build_ideas_color.png';

function GenericInfo() {
  return (
    <div className="row flex podcastGenInfo">
      <div className="flex col-center col col-md-10 col-lg-8">
        <h2 className="podcastGenInfoPara1">
          Der Podcast mit dem du in der digitalen Welt nicht nur überlebst, sondern gedeihst!
        </h2>
        <img className="podcastGenInfoIcon" src={biBulb} alt="build ideas bulb" />
        <p className="podcastGenInfoPara2">
          {' '}
          Dieser Podcast ist ein Muss für alle, die den digitalen Wandel in ihrer Branche meistern
          wollen. Tauchen Sie mit uns ein in tiefgreifende Gespräche, Experteninterviews und
          praxisnahe Ratschläge zu den Themen Digitalisierung, Positionierung und Sichtbarkeit in
          der digitalen Welt. Hören Sie inspirierende Interviews mit Experten und erfahren Sie, wie
          Sie Ihr Unternehmen in der digitalen Ära erfolgreich positionieren können. Abonnieren Sie
          jetzt und bringen Sie Ihr Unternehmen auf die nächste digitale Ebene!{' '}
        </p>
      </div>
    </div>
  );
}

export default GenericInfo;
