import React, { useRef } from 'react';
import './getInTouch.scss';
import { useTranslation } from 'react-i18next';

const iframeStyle = {
  width: '100%',
  height: '650px',
  border: 'none', // Equivalent to frameBorder="0"
  overflow: 'auto', // Equivalent to scrolling="yes"
};

function GetInTouch() {
  const { t } = useTranslation();
  const meetus = useRef(null);

  return (
    <div className="row">
      <div className="col-md-1 col-0"> </div>
      <div className="col-md-4 col-12">
        <div className="getInTouch">
          <h1 className="getInTouchPara1">
            {t('home.section5.heading.part1')}
            <span className="getInTouchPara1InnerText"> {t('home.section5.heading.part2')}</span>
          </h1>
          <p className="getInTouchPara2">{t('home.section5.para')}</p>
        </div>
      </div>
      <div className="col-md-1 col-0"> </div>
      <div className="col-md-6 col-12">
        <iframe
          ref={meetus}
          src="https://meetus.build-ideas.de/ericdelaporte/expertengespraech-eric?is_widget=1&view=compact&view_type=members"
          style={iframeStyle}
          title="meetme"
        />
      </div>
    </div>
  );
}

export default GetInTouch;
