import React from 'react';
import './podcastChecklist.scss';
import image_eric from 'assets/podcast/Eric.jpg';
import icon_check from 'assets/podcast/icon_check.svg';
import { useTranslation } from 'react-i18next';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/extensions

function PodcastChecklist() {
  const { t } = useTranslation();
  return (
    <div className="row container6 podcast">
      <div className="col-md-5 col-12 flex end-start">
        <img src={image_eric} alt="image_eric img-responsive" className="podcast-img" />
      </div>
      <div className="col-md-7 col-12 center podcastRightSide">
        <h2 className="podcastPara2">
          {t('podcast.section3.heading.part1')} <br />
          <span className="colorGold"> {t('podcast.section3.heading.part2')} </span>{' '}
        </h2>
        <ul className="list">
          <li className="list-item">
            <div className="podcast-icon">
              <img src={icon_check} alt="icon_check img-responsive" />
            </div>
            <div className="flex col-start">
              <p className="podcastPara3">
                {t('podcast.section3.list.line1.part1')}
                <span className="colorGold">{t('podcast.section3.list.line1.part2')}</span>
              </p>
            </div>
          </li>
          <li className="list-item">
            <div className="podcast-icon">
              <img src={icon_check} alt="icon_check img-responsive" />
            </div>
            <div className="flex col-start">
              <p className="podcastPara3">
                {t('podcast.section3.list.line2.part1')}
                <span className="colorGold">{t('podcast.section3.list.line2.part2')}</span>
              </p>
            </div>
          </li>
          <li className="list-item">
            <div className="podcast-icon">
              <img src={icon_check} alt="icon_check img-responsive" />
            </div>
            <div className="flex col-start">
              <p className="podcastPara3">
                <span className="colorGold">{t('podcast.section3.list.line3.part1')}</span>
                {t('podcast.section3.list.line3.part2')}
              </p>
            </div>
          </li>
          <li className="list-item">
            <div className="podcast-icon">
              <img src={icon_check} alt="icon_check img-responsive" />
            </div>
            <div className="flex col-start">
              <p className="podcastPara3">
                {t('podcast.section3.list.line4.part1')}
                <span className="colorGold">{t('podcast.section3.list.line4.part2')}</span>
                {t('podcast.section3.list.line4.part3')}
                <span className="colorGold">{t('podcast.section3.list.line4.part4')}</span>
              </p>
            </div>
          </li>
          <li className="list-item">
            <div className="podcast-icon">
              <img src={icon_check} alt="icon_check img-responsive" />
            </div>
            <div className="flex col-start">
              <p className="podcastPara3">
                {t('podcast.section3.list.line5.part1')}
                <span className="colorGold">{t('podcast.section3.list.line5.part2')}</span>
                {t('podcast.section3.list.line5.part3')}
                <p className="colorGold">
                  {t('podcast.section3.list.line5.part4')}
                  <a
                    href="mailto: eric.delaporte@build-ideas.de"
                    type="button"
                    className="podcastMailLink"
                  >
                    <FontAwesomeIcon className="locateIcon podcastMailIcon" icon={faEnvelope} />{' '}
                    E-Mail
                  </a>
                </p>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PodcastChecklist;
