import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <select
      onChange={handleChangeLanguage}
      value={i18n.language}
      className="p-1 border-0 bg-transparent"
    >
      <option value="de">DE</option>
      <option value="en">EN</option>
    </select>
  );
}

export default LanguageSwitcher;
