import React from 'react';
import './fullWidthImage.scss';
import human_resources from 'assets/recruitment/human_resources.png';

function FullWidthImage() {
  return (
    <div className="row full-width">
      <div className="image-container">
        <img src={human_resources} alt="digitalImage img-fluid" className="centered-image" />
      </div>
    </div>
  );
}

export default FullWidthImage;
