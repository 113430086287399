import React from 'react';
import { NavLink } from 'react-router-dom';
import './header.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from 'assets/logo.svg';
import linkedInLogo from 'assets/company/linkedin.svg';
import instagramLogo from 'assets/company/instagram.svg';
import xingLogo from 'assets/company/xing.svg';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher/languageSwitcher';
import { NavDropdown } from 'react-bootstrap';

function Header() {
  const { t } = useTranslation();

  return (
    <Navbar collapseOnSelect expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" height={40} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0 headerIcons" style={{ height: 'auto' }}>
            <Nav.Link href="https://www.linkedin.com/company/build-ideas-gmbh/">
              <img
                src={linkedInLogo}
                alt="LinkedIn"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/buildideasgmbh/">
              <img
                src={instagramLogo}
                alt="Instagram"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />
            </Nav.Link>
            <Nav.Link href="https://www.xing.com/pages/buildideasug">
              <img
                src={xingLogo}
                alt="Xing"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />
            </Nav.Link>
          </Nav>
          <Nav className="ml-auto my-2 my-lg-0" style={{ height: 'auto' }}>
            <div className="nav-link">
              <LanguageSwitcher />
            </div>
            <Nav.Link className="nav-link" to="/" as={NavLink} eventKey="home">
              {t('nav.menu.home')}
            </Nav.Link>
            <NavDropdown title={t('nav.menu.bi')}>
              <NavDropdown.Item
                className="nav-link"
                to="/unternehmen"
                as={NavLink}
                eventKey="company"
              >
                {t('nav.menu.company')}
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-link"
                to="/recruiting"
                as={NavLink}
                eventKey="recruiting"
              >
                {t('nav.menu.recruiting')}
              </NavDropdown.Item>
              <NavDropdown.Item className="nav-link" to="/podcast" as={NavLink} eventKey="podcast">
                {t('nav.menu.podcast')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
