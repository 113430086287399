import React from 'react';
import './podcast.scss';
import FullWidthImage from 'components/podcastComponents/FullWidthImage/fullWidthImage';
import WelcomeSection from 'components/podcastComponents/GenericInfo/genericInfo';
import PodcastChecklist from 'components/podcastComponents/PodcastChecklist/podcastChecklist';
import PodcastList from 'components/podcastComponents/PodcastList/podcastList';
import PodcastApps from 'components/podcastComponents/PodcastApps/podcastApps';

function Podcast() {
  return (
    <>
      <div className="container-fullWidth">
        <FullWidthImage />
      </div>

      <div className="container-fluid">
        <WelcomeSection />
      </div>

      <div className="container-fullWidth">
        <PodcastChecklist />
      </div>

      <div className="container-fullWidth">
        <PodcastList />
      </div>

      <div className="container-fullWidth">
        <PodcastApps />
      </div>
    </>
  );
}

export default Podcast;
