import React from 'react';
import './digitalTech.scss';
import third from 'assets/home/third.png';
import { useTranslation } from 'react-i18next';

function DigitalTech() {
  const { t } = useTranslation();
  return (
    <div className="row digitalTech">
      <div className="col-md-6 col-12">
        <img src={third} alt="digitalImage img-fluid" className="digitalTechImg" />
      </div>
      <div className="col-md-6 col-12 digitalTechRight pb-5 pb-xl-0">
        <h2 className="digitalTechPara1 ">{t('home.section3.heading')}</h2>
        <h3 className="digitalTechPara2 pb-5 pb-xxl-0 pe-3">{t('home.section3.para')}</h3>
      </div>
    </div>
  );
}

export default DigitalTech;
