import React, { useRef } from 'react';
import './Home.scss';
import DigitalSolution from 'components/homeComponents/DigitalSolution/digitalSolution';
import ClientSlider from 'components/homeComponents/ClientSlider/clientSlider';
import DigitalIdea from 'components/homeComponents/DigitalIdea/digitalIdea';
import DigitalTech from 'components/homeComponents/DigitalTech/digitalTech';
import Service from 'components/homeComponents/Services/service';
import GetInTouch from 'components/homeComponents/GetInTouch/getInTouch';

function Home() {
  const contactRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      {/* Container 1 Starts */}
      <div className="container-fluid">
        <DigitalSolution navigateToContactUs={handleButtonClick} />
      </div>
      {/* Container 1 Ends */}

      {/* Container 2 Starts */}
      <div className="container-fluid container2" id="down">
        <div className="row">
          <div className="col">
            <ClientSlider />
          </div>
        </div>
      </div>
      {/* Container 2 Ends */}

      {/* Container 3 Starts */}
      <div className="container-fluid container3">
        <DigitalIdea navigateToContactUs={handleButtonClick} />
      </div>
      {/* Container 3 Ends */}

      {/* Container 4 Starts */}
      <div className="container-fluid container4">
        <DigitalTech />
      </div>
      {/* Container 4 Ends */}

      {/* Container 5 Starts */}
      <div id="services" className="container-fluid container5">
        <Service />
      </div>
      {/* Container 5 Ends */}

      {/* Container 6 Starts */}
      <section ref={contactRef}>
        <div className="container-fluid container6">
          <GetInTouch />
        </div>
      </section>
      {/* Container 6 Ends */}
    </>
  );
}

export default Home;
