import React from 'react';
import './podcastList.scss';
import { useTranslation } from 'react-i18next';

function PodcastList() {
  const { t } = useTranslation();
  return (
    <div className="row podcastlist flex">
      <div className="col-md-12 col-12 flex">
        <h2 className="podcastlistHeading2">
          {t('podcast.section4.heading.part1')}
          <span className="specific">{t('podcast.section4.heading.part2')}</span>
        </h2>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-01481cbe40d90846c6e3867e0af8&v=1698223587"
            title="Podcast#10"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#10 Brands und Brandbuilding</h4>
            <p>
              {' '}
              Du willst eine eigene Brand aufbauen, weißt aber nicht wie das geht? Dann ist das der
              richtige Podcast für Dich!
            </p>
            <p>
              Ich kläre hier klar auf, welche Dinge Du im Vorfeld für Dich klären musst. Welche Art
              von Brand möchtest du erstellen? Was sind die Unterschiede? Was ist einfacher?
            </p>
            <p>
              Schließlich gehen wir noch einmal alles durch was es an Methoden und Dingen gibt, die
              ein Brandbuilding erleichtern und Dir eine klare Struktur an die Hand geben, mit der
              du deine Brand direkt aufbauen kannst.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-94995ecbba5f1c001815670d6d&v=1698223587"
            title="Podcast#9"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#9 BigData und Social Media</h4>
            <p>
              {' '}
              In der heutigen Folge gehe ich näher auf BigData ein. Wodurch definiert es sich
              eigentlich, was macht es aus? Was sind die großen Vs von BigData?
            </p>
            <p>Wie verhält es sich im Marketing und was kann man damit eigentlich alles machen?</p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-44a63a1043b25856fa7295c13758&v=1698223587"
            title="Podcast#8"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#8 Interviewfolge - Marc Torke</h4>
            <p>
              {' '}
              In dieser Folge darf ich meine Fragen dem großartigen Marc Torke stellen. Marc ist
              schon länger im Social Media und Marketing Bereich unterwegs und weiß definitiv wovon
              er spricht. Er war früher schon oft hinter dem Mikro, als er noch Radiomoderator war.
              Er ist mit seiner Agentur aktuell zu den TOP 100 Agenturen für LinkedIn gewählt
              worden. Es lohnt sich also definitiv hier genauer zuzuhören, was er alles so zu sagen
              hat.
            </p>
            <p>Damit ihr ihn jetzt nicht lange suchen müsst, hier seine Kontaktmöglichkeiten:</p>
            <p>Marc Torke - Die Social Media Agentur:</p>
            <p>
              <a href="https://marctorke.de">https://marctorke.de/</a>
            </p>
            <p>
              Auf LinkedIn:{' '}
              <a href="https://www.linkedin.com/in/marctorke">
                https://www.linkedin.com/in/marctorke
              </a>
            </p>
            <p>
              Auf Youtube:{' '}
              <a href="https://www.youtube.com/channel/UCjTjNWvw_Ls6zCSHWyHplpw">
                https://www.youtube.com/channel/UCjTjNWvw_Ls6zCSHWyHplpw
              </a>
            </p>
            <p>
              Auf Facebook:{' '}
              <a href="https://www.facebook.com/diesocialmediaagentur">
                https://www.facebook.com/diesocialmediaagentur
              </a>
            </p>
            <br />
            <p>
              Flowmingo: <a href="https://flowmingo.de/">https://flowmingo.de/</a>
            </p>
            <br />
            <p>
              Auf Facebook:{' '}
              <a href="https://www.facebook.com/flowmingo.de">
                https://www.facebook.com/flowmingo.de
              </a>
            </p>
            <p>
              Auf Instagram:{' '}
              <a href="https://instagram.com/flowmingo.de?igshid=MzRlODBiNWFlZA==">
                https://instagram.com/flowmingo.de?igshid=MzRlODBiNWFlZA==
              </a>
            </p>
            <p>
              Auf TikTok:{' '}
              <a href="https://www.tiktok.com/@flowmingo.de">
                https://www.tiktok.com/@flowmingo.de
              </a>
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-c1fb2aaa4e55f8ba98be75bbaad&v=1698223587"
            title="Podcast#7"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#7 SEO Tools</h4>
            <p>
              {' '}
              Heute gehe ich auf die 3 großen Platzhirsche ein. SE Ranking, Semrush und Ahrefs.
            </p>
            <p>
              {' '}
              Wo liegen die Stärken? Wo liegen die Schwächen? Welche Besonderheiten haben die
              einzelnen Plattformen? All das klären wir hier in dieser Folge!
            </p>
            <p>
              {' '}
              Hier noch wie im Podcast angesprochen eine Übersicht über die einzelnen Details in
              verschiedenen Punkten von den angesprochenen Tools. Ganz am Ende findet ihr noch
              direkte Links zu den einzelnen Produkten!
            </p>
            <p>
              SE Ranking: <a href="https://seranking.com/">https://seranking.com/</a>
            </p>

            <p>
              SEMrush: <a href="https://www.semrush.com/">https://www.semrush.com/</a>
            </p>

            <p>
              ahrefs: <a href="https://ahrefs.com/">https://ahrefs.com/</a>
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-10 leftBox">
          <iframe
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-12176a614aa138bae798723e4ee8&v=1695448921"
            title="Podcast#6"
            className="podcastIframe"
          />
        </div>
        <div className="col-md-8 col-10 rightBox">
          <div>
            <h4 className="bold">#6 Digitales Potenzial</h4>
            <p>
              {' '}
              In dieser Episode tauchen wir tief in das Potenzial der Digitalisierung ein. Wir
              erkennen, dass die digitale Reise weit über das bloße &quot;Weg vom Papier&quot;
              hinausgeht. Es geht darum, Prozesse lebendig zu gestalten, klare Strukturen aufzubauen
              und die Arbeit durch Automatisierung nicht nur effizienter, sondern auch sicherer zu
              machen. Wir sprechen über die Bedeutung des digitalen Marketings, von der Nutzung von
              Online-Strategien bis hin zur Suchmaschinenoptimierung, und wie Unternehmen durch
              E-Commerce wachsen können. Dabei dürfen wir natürlich die Transformation interner
              Prozesse und die Entwicklung innovativer Produkte nicht vergessen, die an die
              Bedürfnisse unserer digitalen Zielgruppe angepasst sind. Wir diskutieren, wie wichtig
              die Kundenkommunikation in dieser Ära ist und warum Tools wie Chatbots oder eine
              aktive Präsenz in den sozialen Medien entscheidend sind. Ein weiterer Schwerpunkt
              liegt auf der Online-Präsenz: Wie gestaltet man eine benutzerfreundliche Website?
              Warum ist SEO so wichtig? Wie können wir unsere Inhalte optimal gestalten und
              präsentieren? Dabei dürfen wir den Datenschutz und die Cybersicherheit nicht
              vernachlässigen. Abschließend sprechen wir über die Chancen, die sich durch die
              digitale Sichtbarkeit ergeben, sowohl für Unternehmen als auch für das Tracking von
              Besucherverhalten, und wie all diese Maßnahmen letztlich zu einem langfristigen Erfolg
              beitragen können. Begleitet mich auf dieser spannenden Reise durch die Welt der
              Digitalisierung!
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#5"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-6e061c9d7932b76d41d2da5f1e8&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#5 Interviewfolge - Sirit Coeppicus</h4>
            <p>
              {' '}
              Da ist sie. Die erste Interviewfolge! Heute im Interview die einzigartige, die
              wunderbare, die phänomenale Sirit Coeppicus! Sirit ist ihres Zeichens eine echte
              Netzwerkerin, Texterin, Social Media Expertin und wunderbare Person. Wir haben uns im
              Coworking Space von worqs Aachen getroffen und nach ein paar technischen
              Schwierigkeiten dann am Ende unter freiem Himmel mit Vogelgezwitscher das Interview
              aufgenommen.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#4"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-ba0cf20f389f9442871c1e886&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#4 KI & SEO - was macht die KI eigentlich?</h4>
            <p>
              {' '}
              In dieser Folge sprechen wir über die Schnittstelle von KI und SEO, insbesondere über
              das KI-Modell GPT. GPT wurde mit einem enormen Datensatz trainiert, von Büchern bis zu
              sozialen Medien, und kann kohärent und relevant auf Anfragen antworten. Es kann seinen
              Schreibstil an verschiedene Anforderungen anpassen, von formal bis locker, und findet
              Anwendungen in Bereichen wie Content-Erstellung und Kundensupport. Trotz seiner
              Fähigkeiten besitzt GPT keine menschliche Intelligenz. Es verarbeitet Texte basierend
              auf statistischen Mustern und verwendet &quot;Tokens&quot; zur Textgenerierung. Seine
              &quot;Intelligenz&quot; ist jedoch begrenzt: Es hat kein tiefes Verständnis für
              Konzepte und sein Wissen endet im September 2021. Daher ist es nicht immer aktuell und
              sollte mit Vorsicht verwendet werden, insbesondere in ethischen oder
              datenschutzrelevanten Bereichen. Im Bereich SEO kann GPT nützlich sein, um Textinhalte
              effizient zu generieren und SEO-Best Practices zu befolgen.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#3"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-e918ae54620b5cbc44c04e79e58&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#3 Sichtbarkeit - Das entscheidende Phänomen</h4>
            <p>
              {' '}
              In dieser Folge nehme ich Euch mit auf eine Reise durch die facettenreiche Welt der
              Digitalisierung. Vom Wandel im Büroalltag bis zu großen Umbrüchen im E-Commerce – die
              Digitalisierung prägt, wie wir arbeiten, lernen und kommunizieren.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#2"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-46936f1c85c166066918bd97258&v=1695448295"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#2 - Positionierung - Wen und wo eigentlich</h4>
            <p>
              {' '}
              In dieser Folge meines Podcasts &quot;Digitalisierung. Positionierung.
              Sichtbarkeit.&quot; spreche ich darüber, wie man sich in der digitalen Ära erfolgreich
              positioniert, sei es auf Social Media, in Suchergebnissen oder auch offline. Ich
              betone, dass eine kohärente und authentische Online-Präsenz, gepaart mit Konsistenz,
              der Schlüssel zur Positionierung ist. Dabei nenne ich erfolgreiche Marken wie Apple
              und Nike als Beispiele für Konsistenz in Branding-Strategien. Ich gehe auch auf die
              Wichtigkeit von SEO ein, um in Suchergebnissen sichtbar zu sein. Darüber hinaus
              diskutiere ich die Relevanz der physischen Präsenz auf Messen und in Printmedien und
              wie ein starker visueller Eindruck eine Marke definieren kann. Ein Beispiel ist die
              Firma &quot;GreenDrive&quot; mit ihrem umweltfreundlichen Messestand. Zum Abschluss
              dieser Folge weise ich darauf hin, dass die Erkennung eigener Stärken und
              Leidenschaften der Schlüssel zur Positionierung ist und dass die digitale Welt
              ständige Anpassungen erfordert. Ich hoffe, ihr könnt einige wertvolle Erkenntnisse
              mitnehmen und freue mich auf zukünftige Diskussionen in kommenden Folgen! Es wäre
              toll, wenn ihr meinem Podcast eine 5-Sterne-Bewertung geben könntet.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Podcast#1"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-5a4d65d8a2ede78462d5d1ce478&v=1695448921"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#1 - Digitalisierung - Eine Begriffserklärung</h4>
            <p>
              {' '}
              In dieser Folge nehme ich Euch mit auf eine Reise durch die facettenreiche Welt der
              Digitalisierung. Vom Wandel im Büroalltag bis zu großen Umbrüchen im E-Commerce – die
              Digitalisierung prägt, wie wir arbeiten, lernen und kommunizieren.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-md-10 col-10 podcastBoxWrapper">
        <div className="col-md-4 col-12 leftBox">
          <iframe
            className="podcastIframe"
            title="Episode#0"
            src="https://www.podcaster.de/whiteplayer/?id=show~41rh5b~digitalisierung-positionierung-sichtbarkeit~pod-fa53ec41bc069832e51adce31418&v=1695448295"
          />
        </div>
        <div className="col-md-8 col-12 rightBox">
          <div>
            <h4 className="bold">#0 - Intro</h4>
            <p>
              {' '}
              In dieser Folge nehme ich Euch mit auf eine Reise durch die facettenreiche Welt der
              Digitalisierung. Vom Wandel im Büroalltag bis zu großen Umbrüchen im E-Commerce – die
              Digitalisierung prägt, wie wir arbeiten, lernen und kommunizieren.
            </p>
            <p>
              Webseite:{' '}
              <a href="https://www.build-ideas.de/podcast">https://www.build-ideas.de/podcast</a>
            </p>
            <p>Direkter Kontakt per Mail: kontakt@build-ideas.de</p>
            <p>Direkter Kontakt per Telefon: +49 (0) 2644 406 40 37</p>
          </div>
        </div>
      </div>
      <div className="license flex py-5">
        <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
          <img
            alt="Creative Commons Lizenzvertrag"
            src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
          />
        </a>
        <br />
        Dieses Werk ist lizenziert unter einer{' '}
        <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
          Creative Commons Namensnennung - Nicht kommerziell - Keine Bearbeitungen 4.0 International
          Lizenz
        </a>
      </div>
    </div>
  );
}

export default PodcastList;
