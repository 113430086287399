import React from 'react';
import './branding.scss';
import headhunting_recruiting from 'assets/recruitment/headhunting_recruiting.jpg';
import businesswoman_webcam from 'assets/recruitment/businesswoman_webcam.jpg';

function Branding() {
  return (
    <div className="row branding">
      <div className="col-md-12 brandingHead1">
        <h3 className="brandingHead3"> Recruitment im Detail</h3>
        <h2 className="brandingHead2">Aufbau der Arbeitgebermarke</h2>
      </div>
      <div className="col-md-6 col-12 flex">
        <img
          src={headhunting_recruiting}
          alt="headhunting_recruiting img-responsive"
          className="brandingImg leftOffset"
        />
      </div>
      <div className="col-md-6 col-12">
        <div className="branding">
          <h2 className="brandingPara1">Strategieentwicklung & Consulting</h2>
          <ul className="brandingList">
            {' '}
            <h3>
              {' '}
              <span className="brandingPara1InnerText">Unternehmensanalyse:</span>
            </h3>
            <li>Wettbewerbsanalyse</li>
            <li>IST-Analyse & Entwicklung eines SOLL- Konzepts zur sofortigen Steigerung der</li>
            <li>Arbeitgeberattraktivität inkl. 1:1 Benefit Checkliste</li>
            <li>
              Aufbau einer Arbeitgebermarke und Ausarbeitung einer einzigartigen Arbeitgeber- DNA
            </li>{' '}
            <h3>
              {' '}
              <span className="brandingPara1InnerText">Stellenanalyse:</span>
            </h3>
            <li>Zielgruppenanalyse inkl. der emotionalen und rationalen Wechselmotive</li>
            <li>Definition der menschlichen und fachlichen Qualifikation des Wunschmitarbeiters</li>
            <li>Analyse und Erstellung einer attraktiven Stellenausschreibung</li>
            <h2 className="brandingPara1 brandingPara1Specific mt-5">Recruitingsystem</h2>
            <h3>
              {' '}
              <span className="brandingPara1InnerText">Konzeptionierung & Programmierung:</span>
            </h3>
            <li>Zielgruppenanalyse inkl. der emotionalen und rationalen Wechselmotive</li>
            <li>Definition der menschlichen und fachlichen Qualifikation des Wunschmitarbeiters</li>
            <li>Analyse und Erstellung einer attraktiven Stellenausschreibung</li>
          </ul>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div className="branding spacer-ml-3">
          <h2 className="brandingPara1">Betreuung</h2>
          <ul className="brandingList">
            {' '}
            <h3>
              {' '}
              <span className="brandingPara1InnerText">
                Weiterentwicklung & Betreuung für den Leistungszeitraum
              </span>
            </h3>
            <li>Kick-Off und systematisches Onboarding</li>
            <li>Dedizierter Projektmanager über den gesamten Leistungszeitraum</li>
            <li>Support per Telefon & E-Mail</li>
            <li>
              Konstante Weiterentwicklung, Betreuung & Optimierung der Social Media Kampagnen
            </li>{' '}
            <li>Monitoring der Werbekampagnen</li>
            <li>Aufsetzen von neuen bezahlten Werbekampagnen</li>
            <li>Recherche und Erstellung von Werbeinhalten</li>
            <h3>
              {' '}
              <span className="brandingPara1InnerText">Bewerbermanagement</span>
            </h3>
            <li>Telefonische Vorqualifikation der Bewerber</li>
            <li>Abfragen Must-Haves</li>
            <li>Telefonische Nachqualifizierung aller eingegangen Bewerbungen</li>
            <li>Einforderung der gewünschten Bewerbungsunterlagen</li>
            <li>Vorfilterung durch Persönlichkeitstests</li>
            <li>Weiterempfehlung von geeigneten Kandidaten</li>
          </ul>
          <a href="/recruiting#getInTouch">
            <button type="button" className="btn defaultButton brandingButton">
              Jetzt starten
            </button>
          </a>
        </div>
      </div>
      <div className="col-md-6 col-12 flex">
        <img
          src={businesswoman_webcam}
          alt="businesswoman_webcam img-responsive"
          className="brandingImg rightOffset"
        />
      </div>
    </div>
  );
}
export default Branding;
