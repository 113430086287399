import React from 'react';
import './clientSlider.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from 'assets/clients/aundo.client.png';
import img2 from 'assets/clients/km-schreinerei.client.png';
import img3 from 'assets/clients/Logo_Marion_Vogel.client.png';
import img4 from 'assets/clients/Logo_RHM.client.png';
import img5 from 'assets/clients/myprocess.client.png';
import img6 from 'assets/clients/ottohutt.client.png';

function ClientSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 6000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Slider {...settings}>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img1} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img2} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img3} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img4} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img5} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img6} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img1} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img2} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img3} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img4} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img5} alt="" />
          </div>
        </div>
        <div className="tst-crd">
          <div className="image_wrapper">
            <img src={img6} alt="" />
          </div>
        </div>
      </Slider>
    </>
  );
}

export default ClientSlider;
