import React from 'react';
import './digitalSolution.scss';
import firstImage from 'assets/home/first.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type DigitalSolutionProps = {
  navigateToContactUs: () => void;
};
function DigitalSolution({ navigateToContactUs }: DigitalSolutionProps) {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-6 col-12">
        <div className="container1 ms-lg-5">
          <h1 className="container1Para1">
            <span className="container1Para1InnerText">{t('home.section1.heading.part1')} </span>{' '}
            {t('home.section1.heading.part2')} <br />
            {t('home.section1.subheading.part1')} <br />
            {t('home.section1.subheading.part2')}
          </h1>
          <p className="container1Para2">{t('home.section1.para')}</p>
          <button
            type="button"
            className="btn defaultButton defaultButton"
            onClick={navigateToContactUs}
          >
            {t('home.section1.cta_button')}
            <FontAwesomeIcon className="locateIcon" icon={faArrowRight} />
          </button>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <img src={firstImage} alt="firstImage img-responsive" className="firstImg" />
      </div>
    </div>
  );
}

export default DigitalSolution;
