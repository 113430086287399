import React from 'react';
import './digitalIdea.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import second from 'assets/company/second.png';
import { useTranslation } from 'react-i18next';

type DigitalIdeaProps = {
  navigateToContactUs: () => void;
};
function DigitalIdea({ navigateToContactUs }: DigitalIdeaProps) {
  const { t } = useTranslation();
  return (
    <div className="row digitalIdea">
      <div className="left-column col-md-5 col-12 digitalimagecol ">
        <img src={second} alt="digitalImage img-fluid" className="digitalImg" />
      </div>
      <div className="right-column col-md-7 col-12 py-md-3 py-lg-0">
        <h2 className="digitalIdeaPara1">build ideas.</h2>
        <h3 className="digitalIdeaPara2">
          {t('home.section2.subheading.part1')}{' '}
          <span className="specific">{t('home.section2.subheading.part2')}</span> - <br />
          {t('home.section2.subheading.part3')}
        </h3>
        <p className="digitalIdeaPara3 ms-lg-5">{t('home.section2.para')}</p>
        <button
          type="button"
          className="btn defaultButton  digitalIdeaButton mb-md-3 mb-lg-5 mb-xl-0"
          onClick={navigateToContactUs}
        >
          {t('home.section2.cta_button')}
          <FontAwesomeIcon className="locateIcon" icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
}

export default DigitalIdea;
