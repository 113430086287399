import React from 'react';
import './podcastApps.scss';
import { useTranslation } from 'react-i18next';
import logo_amazon from 'assets/podcast/uco-amazon-music-logo.svg';
import logo_deezer from 'assets/podcast/uco-deezer-logo.svg';
import logo_googlePodcasts from 'assets/podcast/uco-google-podcasts-logo.svg';
import logo_audible from 'assets/podcast/audible_logo.png';
import logo_spotify from 'assets/podcast/uco-spotify-logo.svg';
import logo_apple from 'assets/podcast/uco-apple-podcasts-logo.svg';
import logo_podcastDE from 'assets/podcast/podcast.de.svg';

function PodcastApps() {
  const { t } = useTranslation();
  return (
    <div className="row podcastapps">
      <div className="col-md-12 col-12 podcastappsHeadingWrapper flex">
        <h2 className="podcastappsHeading2">
          {' '}
          {t('podcast.section5.heading.part1')}
          <span className="specific">{t('podcast.section5.heading.part2')}</span>{' '}
        </h2>
      </div>
      <div className="row flex">
        <div className="box col-12 col-md-4 g-4">
          <a
            target="_blank"
            href="https://open.spotify.com/show/47MDqYyi0rzs9GA6RAdg3O"
            rel="noreferrer"
          >
            <img src={logo_spotify} alt="Logo" height="40" />
          </a>
        </div>
        <div className="box col-12 col-md-4 g-4">
          <a
            target="_blank"
            href="https://podcasts.apple.com/us/podcast/digitalisierung-positionierung-sichtbarkeit/id1704461305"
            rel="noreferrer"
          >
            <img src={logo_apple} alt="Logo" height="40" />
          </a>
        </div>
        <div className="box col-12 col-md-4 g-4">
          <a
            target="_blank"
            href="https://www.podcast.de/podcast/3309558/digitalisierung-positionierung-sichtbarkeit"
            rel="noreferrer"
          >
            <img src={logo_podcastDE} alt="Logo" height="40" />
          </a>
        </div>
        <div className="box col-12 col-md-4 g-4">
          <a
            target="_blank"
            href="https://music.amazon.de/podcasts/eff5e038-98b7-4373-80cd-3cb8675eb6b1/digitalisierung-positionierung-sichtbarkeit"
            rel="noreferrer"
          >
            <img src={logo_amazon} alt="Logo" height="40" />
          </a>
        </div>
        <div className="box col-12 col-md-4 g-4">
          <a
            target="_blank"
            href="https://www.audible.de/pd/Digitalisierung-Positionierung-Sichtbarkeit-Podcast/B0CGNLH1KK?source_code=ASSOR150021221000N"
            rel="noreferrer"
          >
            <img src={logo_audible} alt="Logo" height="40" />
          </a>
        </div>
        <div className="box col-12 col-md-4 g-4">
          <a
            target="_blank"
            href="https://podcasts.google.com/feed/aHR0cHM6Ly9idWlsZC1pZGVhcy5wb2RjYXN0ZXIuZGUvZGlnaXRhbGlzaWVydW5nLXBvc2l0aW9uaWVydW5nLXNpY2h0YmFya2VpdC5yc3M"
            rel="noreferrer"
          >
            <img src={logo_googlePodcasts} alt="Logo" height="40" />
          </a>
        </div>
        <div className="box col-12 col-md-4 g-4">
          <a target="_blank" href="https://www.deezer.com/de/show/1000210935" rel="noreferrer">
            <img src={logo_deezer} alt="Logo" height="40" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default PodcastApps;
