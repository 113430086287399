import React from 'react';
import './service.scss';
import Accordion from 'react-bootstrap/Accordion';
import faqTop from 'assets/home/faq-top.jpg';
import faqBottom from 'assets/home/faq-bottom.jpg';
import serviceIcon1 from 'assets/home/serviceIcon1.png';
import serviceIcon2 from 'assets/home/serviceIcon2.png';
import serviceIcon3 from 'assets/home/serviceIcon3.png';
import serviceIcon4 from 'assets/home/serviceIcon4.png';
import { useTranslation } from 'react-i18next';
// todo :add service icon for fifth accordion

type AccordionItem = {
  header: {
    part1: string;
    part2: string;
  };
  body: string;
};
function Service() {
  const { t } = useTranslation();
  const accordions: AccordionItem[] = t('home.section4.accordion', { returnObjects: true }) as [];
  const serviceIcons = [serviceIcon1, serviceIcon2, serviceIcon3, serviceIcon4];
  return (
    <div className="row service">
      <h1 className="serviceHeading1 pb-4">{t('home.section4.heading')}</h1>
      <div className="col-md-4 col-12 serviceColPadding">
        <div className="row g-0 imgRow">
          <img src={faqTop} alt="digitalImage img-fluid" className="serviceImg" />
        </div>
        <div className="row g-0 imgRow">
          <img src={faqBottom} alt="digitalImage img-fluid" className="serviceImg" />
        </div>
      </div>
      <div className="col-md-8 col-12 serviceColPadding">
        <Accordion defaultActiveKey="0" flush>
          {accordions.map((item, index) => {
            return (
              <Accordion.Item eventKey={index.toString()} className="accordionItem">
                <Accordion.Header>
                  <img src={serviceIcons.at(index) ?? serviceIcon4} alt="digitalImage img-fluid" />
                  <span className="serviceIconNo">0{index + 1}</span>{' '}
                  <span className="serviceAccordionHeading">{item.header.part1}</span>
                  <h2 className="serviceAccordionSubHeading">{item.header.part2}</h2>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="serviceAccordionBodyContent">{item.body}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}

export default Service;
