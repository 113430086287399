import React from 'react';
import './buildDigital.scss';
import time_managment_rund from 'assets/recruitment/time_managment.jpg';
import { useTranslation } from 'react-i18next';

function BuildDigital() {
  const { t } = useTranslation();
  return (
    <div className="row flex-column-reverse flex-sm-row">
      <div className="col-md-6 col-12 flex">
        <div className="buildDigital">
          <h1 className="buildDigitalPara1">
            {t('recruiting.section1.heading.part1')}
            <br />
            {t('recruiting.section1.heading.part2')}
            <span className="buildDigitalPara1InnerText bold">
              {' '}
              {t('recruiting.section1.heading.part3')}
            </span>
          </h1>
          <p className="buildDigitalPara2">{t('recruiting.section1.para')}</p>
          <a href="/recruiting#getInTouch">
            <button type="button" className="btn defaultButton">
              Jetzt starten
            </button>
          </a>
        </div>
      </div>
      <div className="col-md-6 col-12 flex">
        <img
          src={time_managment_rund}
          alt="firstImage img-responsive"
          className="buildDigitalImg"
        />
      </div>
    </div>
  );
}

export default BuildDigital;
