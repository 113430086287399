import React from 'react';
import './footer.scss';
import logo from 'assets/logo.svg';
import linkedInLogo from 'assets/company/linkedin.svg';
import instagramLogo from 'assets/company/instagram.svg';
import xingLogo from 'assets/company/xing.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid">
      <div className="row footerSection">
        <div className="row">
          <div className="col-sm-6">
            <div className="footerLogo">
              <img src={logo} alt="Logo" height="40" />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="footerIcons  float-sm-end pe-1 my-2 my-sm-0">
              <span>
                <a href="https://www.linkedin.com/company/build-ideas-gmbh/">
                  <img src={linkedInLogo} alt="LinkedIn" />
                </a>
              </span>
              <span>
                <a href="https://www.instagram.com/buildideasgmbh/">
                  <img src={instagramLogo} alt="Instagram" />
                </a>
              </span>
              <span>
                <a href="https://www.xing.com/pages/buildideasug">
                  <img src={xingLogo} alt="Xing" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 col-12 d-flex">
            <div className="footerList">
              <span>2023&copy; build ideas GmbH</span>
              <span className=" d-none d-sm-inline">|</span>
              <Link to="/Datenschutz">
                <span className="uppercase my-3 my-md-0">{t('footer.item.privacy')}</span>
              </Link>
              <span className=" d-none d-sm-inline">|</span>
              <Link to="/Impressum">
                <span className="uppercase mt-3 my-md-0">{t('footer.item.imprint')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
